<template>
  <CSidebar
    :unfoldable="sidebarUnfoldable"
    :narrow="sidebar.sidebarNarrow"
    :visible="sidebar.sidebarVisible"
    position="fixed"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CImage
        class="c-sidebar-brand-full"
        :hidden="sidebar.sidebarNarrow === true"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
        src="/img/PRS_Tools_Logo.svg"
      />
      <CImage
        class="c-sidebar-brand-minimized"
        :hidden="sidebar.sidebarNarrow === false"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
        src="/img/PRS_icon.svg"
      />
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler
      class="d-md-down-none"
      title="toggle the sidebar"
      @click="sidebar.toggleSidebar()"
    />
  </CSidebar>
</template>

<script setup>
import { computed } from 'vue'
import { useSidebarStore } from '@/stores/sidebarStore'
import { AppSidebarNav } from './AppSidebarNav'

// const role = 'admin'
const sidebar = useSidebarStore()
const sidebarUnfoldable = computed(() => sidebar.sidebarUnfoldable)
// const sidebarNarrow = computed(() => sidebar.sidebarNarrow)
</script>
