// src/stores/index.js
import { reactive } from 'vue'
import { defineStore } from 'pinia'

let nextId = 1

export const useNotificationStore = defineStore('notifications', {
  state: () => {
    return {
      notifications: reactive([])
    }
  },
  actions: {
    add (notification) {
      this.notifications.push({
        ...notification,
        id: nextId++
      })
    },
    remove (notificationToRemove) {
      this.notifications = this.notifications.filter(
        notification => notification.id !== notificationToRemove
      )
    }
  }
})
