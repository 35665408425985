<template>
  <div class="base-timer">
    <svg
      class="base-timer__svg"
      viewBox="0 0 200 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="base-timer__circle">
        <circle
          class="base-timer__path-elapsed"
          cx="50%"
          cy="50%"
          r="45"
        />
        <path
          :stroke-dasharray="circleDasharray"
          class="base-timer__path-remaining"
          :class="remainingPathColor"
          d="
            M 100, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
        "></path>
      </g>
    </svg>
    <span class="base-timer__label">
      <!-- {{ formattedTimeLeft }} -->
      {{ timeLeft }}
    </span>
  </div>
</template>

<script setup>
import { computed, ref, watch, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores/authStore'

const authStore = useAuthStore()
const { isAuthenticated } = authStore
const { idleModalVisible } = storeToRefs(authStore)

const props = defineProps({
  timeLeft: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['timerExpired'])

const FULL_DASH_ARRAY = 283
const WARNING_THRESHOLD = 10
const ALERT_THRESHOLD = 5
const COLOR_CODES = {
  info: {
    color: 'green'
  },
  warning: {
    color: 'orange',
    threshold: WARNING_THRESHOLD
  },
  alert: {
    color: 'red',
    threshold: ALERT_THRESHOLD
  }
}

const timeLimit = ref(props.timeLeft)
const timePassed = ref(0)
const timerInterval = ref(null)

const timeLeft = computed(() => { // eslint-disable-line vue/no-dupe-keys
  return timeLimit.value - timePassed.value
})

const timeFraction = computed(() => {
  const rawTimeFraction = timeLeft.value / timeLimit.value
  return rawTimeFraction - (1 / timeLimit.value) * (1 - rawTimeFraction)
})

const remainingPathColor = computed(() => {
  const { alert, warning, info } = COLOR_CODES

  if (timeLeft.value <= alert.threshold) {
    return alert.color
  } else if (timeLeft.value <= warning.threshold) {
    return warning.color
  } else {
    return info.color
  }
})

/*
const formattedTimeLeft = computed(() => { // eslint-disable-line no-unused-vars
  const timeLeft = props.timeLeft

  // The largest round integer less than or equal to the result of time divided being by 60.
  const minutes = Math.floor(timeLeft / 60)

  // Seconds are the remainder of the time divided by 60(modulus operator)
  let seconds = timeLeft % 60

  // If the value of seconds is less than 10, then display seconds with a leading zero
  if (seconds < 10) {
    seconds = `0${seconds}`
  }
  // The output in MM:SS format
  return `${minutes}:${seconds}`
})
*/

const startTimer = () => {
  console.debug('starting timer')
  timerInterval.value = setInterval(() => (timePassed.value += 1), 1000)
}

const onTimesUp = () => {
  clearInterval(timerInterval.value)
  emit('timerExpired')
}

watchEffect(() => {
  if (idleModalVisible.value && isAuthenticated()) startTimer()
})

watch(timeLeft, (left, prevLeft) => {
  if (left === 0) onTimesUp()
})

const circleDasharray = computed(() => {
  return `${(timeFraction.value * FULL_DASH_ARRAY).toFixed(0)} ${FULL_DASH_ARRAY}`
})
</script>

<style scoped lang="scss">
.base-timer {
  position: relative;
  width: 300px;
  height: 150px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 300px;
    height: 150px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}
</style>
