import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
// import { useStorage } from '@vueuse/core'
import { isEmpty } from 'lodash'
import { useMsal } from '@/composables/msal'
import User from '@/models/User'

export const useAuthStore = defineStore('auth', () => {
  const msalConfig = ref({
    auth: {
      clientId: process.env.VUE_APP_AZURE_APP_ID,
      // authority: 'https://login.microsoftonline.com/bce23ee0-eab2-49b9-9396-b361cc309367',
      // authority: 'https://login.microsoftonline.com/common',
      authority: 'https://login.microsoftonline.com/organizations',
      redirectUri: '/'
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false // Set this to 'true' if you are having issues on IE11 or Edge
    }
  })
  const idToken = ref('')
  // const returnUrl = ref(null)
  const idleModalVisible = ref(false)
  const logoutTimerCount = ref(60)

  /*
  const currentUser = computed(() => {
    return localStorage.currentUser
      ? null
      : localStorage.currentUser
  })
  */
  const currentUser = ref(null)

  const newLogin = (loginType, account, token) => {
    /*
    const vm = getCurrentInstance()
    vm.appContext.config.globalProperties
      .$msalInstance.setActiveAccount(account)
      */
    const newUser = new User(loginType, account, token)
    currentUser.value = newUser
  }

  const isAuthenticated = computed(() => {
    const authenticated = !isEmpty(currentUser.value) &&
      Date.now() < currentUser.value.expiry * 1000
    return authenticated
  })

  const isAdmin = () => {
    return currentUser.value.roles &&
      currentUser.value.roles.some(role => {
        return role === 'admin'
      })
  }

  const logout = () => {
    delete currentUser.value
    // this.$reset()
  }

  // instantiate the msal application context
  const msal = useMsal(msalConfig.value)

  msal.addEventCallback(message => {
    // console.debug('msal event message: ', message)
    const gotToken = [
      'msal:loginSuccess',
      'msal:acquireTokenSuccess'
    ].includes(message.eventType)

    if (gotToken) {
      const { payload } = message
      // Get idToken(JWT) from the payload.
      idToken.value = payload.idToken
    }
  })

  return {
    currentUser,
    idleModalVisible,
    idToken,
    isAuthenticated,
    isAdmin,
    logout,
    logoutTimerCount,
    msal,
    newLogin
  }
}, {
  persist: true
})