import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { freeSet as icons } from '@coreui/icons'
import { library } from '@/lib/font-awesome' // eslint-disable-line no-unused-vars
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SmartTable from 'vuejs-smart-table'
import VueDatePicker from '@vuepic/vue-datepicker'
// import Vuelidate from 'vuelidate'

import './assets/css/site.scss'
import '@vuepic/vue-datepicker/dist/main.css'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
app.use(router)
app.use(pinia)
app.use(CoreuiVue)
app.use(SmartTable)
app.provide('icons', icons)
app.component('CIcon', CIcon)
// app.component('CAccordian', CoreuiVue.CAccordian)
app.component('DatePicker', VueDatePicker)
app.component('font-awesome-icon', FontAwesomeIcon)

app.config.globalProperties.$msalInstance = {}
app.mount('#app')
