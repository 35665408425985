<template>
  <CModal :visible="idleModalVisible" backdrop="static" fullscreen>
    <CModalHeader :close-button="false">
      <CRow class="w-100">
        <CCol>
          <h1 class="text-center">Idle Timeout</h1>
        </CCol>
      </CRow>
    </CModalHeader>
    <CModalBody>
      <CRow class="">
        <CCol lg="4" class="align-self-center mx-auto">
          <CountdownTimer class="mx-auto" :time-left="timeLeft" @timerExpired="logout()" />
        </CCol>
      </CRow>
      <CRow class="w-100 mt-3 mx-0">
        <CCol lg="2" class="self-align-center mx-auto">
          <CButton
            color="secondary"
            variant="outline"
            @click="logout()"
            class="w-100 m-0">
            Logout
          </CButton>
        </CCol>
      </CRow>
      <CRow class="w-100 mt-3 mx-0">
        <CCol lg="2" class="self-align-center mx-auto">
          <CButton
            color="primary"
            @click="stayLoggedIn()"
            class="w-100 m-0"
          >
            I'm Still Here
          </CButton>
        </CCol>
      </CRow>
    </CModalBody>
  </CModal>
</template>

<script setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore } from '@/stores/mainStore'
import { useAuthStore } from '@/stores/authStore'
import CountdownTimer from '@/components/Utilities/CountdownTimer'

const { app_logout_timer } = useStore() // eslint-disable-line camelcase
const authStore = useAuthStore()
const { logout } = authStore
const { idleModalVisible } = storeToRefs(authStore)

console.debug('app_logout_timer: ', app_logout_timer)
const timeLeft = ref(app_logout_timer) // eslint-disable-line camelcase

const stayLoggedIn = () => {
  idleModalVisible.value = false
}
</script>

<style scoped lang="scss">
button {
  margin: 3rem;
}
</style>