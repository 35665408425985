export default {
  app_login_expiry: 1800, // defined in seconds
  app_logout_timer: 60, // defined in seconds
  crm_base_url: 'https://prsdata.freshsales.io',
  helpdesk_base_url: 'https://support.prsdata.com',
  the_book_url: 'https://thebook.prsdata.net',
  customer_lake: {
    glue_jobs: [
      'caretracker-transform-job-dev',
      'kareo-transform-job-dev',
      'PivotPayrollJournal'
    ],
    data_quality_jobs: [
      'AllClaimsDataQualityCheck'
    ],
    input_tables: [
      { sourceName: 'Caretracker', tableName: 'caretracker_import' }
    ],
    lakeTableName: 'prsdata_lake_customer-dev',
    queries: {
      recordCount: {
        category: 'recordCount',
        query: `
          SELECT COUNT(*) AS record_count
          FROM "AwsDataCatalog"."prs_data_lake_customer-dev"."all_claims_data_dev" 
        `
      },
      encounterCount: {
        category: 'recordCount',
        query: `
          SELECT COUNT(DISTINCT encounter_id) AS encounter_count
          FROM "AwsDataCatalog"."prs_data_lake_customer-dev"."all_claims_data_dev"
          `
      },
      customerCount: {
        category: 'recordCount',
        query: `
          SELECT COUNT(DISTINCT "company_id") AS customer_count
          FROM "AwsDataCatalog"."prs_data_lake_customer-dev"."all_claims_data_dev"
          `
      },
      providerCount: {
        category: 'recordCount',
        query: `
          SELECT COUNT(DISTINCT billingprovidernpi) AS provider_count
          FROM "AwsDataCatalog"."prs_data_lake_customer-dev"."all_claims_data_dev"
          `
      },
      patientCount: {
        category: 'recordCount',
        query: `
          SELECT COUNT(DISTINCT "pat_id") AS patient_count
          FROM "AwsDataCatalog"."prs_data_lake_customer-dev"."all_claims_data_dev"
          `
      },
      dataEpochMin: {
        category: 'range',
        query: `
          SELECT min("trans_date") AS transaction_date
          FROM "AwsDataCatalog"."prs_data_lake_customer-dev"."all_claims_data_dev"
        `
      },
      dataEpochMax: {
        category: 'range',
        query: `
          SELECT max("trans_date") AS transaction_date
          FROM "AwsDataCatalog"."prs_data_lake_customer-dev"."all_claims_data_dev"
        `
      },
      customerRecordsTrend12Mo: {
        category: 'trend',
        query: `
          WITH 
          temp1 AS
          (
          SELECT date_trunc('month', "trans_date") AS transaction_month,
                COUNT(*) as records
          FROM "AwsDataCatalog"."prs_data_lake_customer-dev"."all_claims_data_dev"
          GROUP BY date_trunc('month', "trans_date")
          ),
          temp2 AS
          (
          SELECT date_trunc('month', "trans_date") AS transaction_month,
                COUNT(*) as records
          FROM "AwsDataCatalog"."prs_data_lake_customer-dev"."all_claims_data_dev"
          GROUP BY date_trunc('month', "trans_date")
          )

          SELECT transaction_month,
            records AS cumulative_records,
            (
              SELECT SUM(records) as records
              FROM temp2
              WHERE temp2.transaction_month < temp1.transaction_month
            ) AS monthly_records
          FROM temp1
          ORDER BY transaction_month DESC
          LIMIT 12
        `
      },
      prsInvoices12Mo: {
        category: 'trend',
        query: `
          SELECT date_trunc('MONTH', txndate ) as Month,
                ROUND(SUM(subtotal)) AS Sales
          FROM "AwsDataCatalog"."quickbooks_prs"."awsgluedatabrew_invoice_extract"
          WHERE   date_trunc('MONTH', txndate ) < current_date - interval '6' month
            AND   date_trunc('MONTH', txndate ) > current_date - interval '18' month
          GROUP BY date_trunc('MONTH', txndate)
          ORDER BY date_trunc('MONTH', txndate) ASC
          `
      }
    }
  }
}
