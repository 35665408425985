<template>
  <div class="notification-container">
    <CToaster placement="top-end">
      <CToast v-for="notification in notificationStore.notifications"
              class="text-light text-start"
              :color="notification.type"
              focusable="false"
              :key="notification.id"
              :notification="notification"
              @close.once="removeNotification(notification.id)"
      >
        <div class="row">
          <div class="col-10">
            <CToastBody :class="getColor(notification.type)">
                {{ notification.message }}
            </CToastBody>
          </div>
          <div class="col-2 pt-2">
            <CToastClose class="me-2 m-auto float-end" :white="isWhite(notification.type)" />
          </div>
        </div>
      </CToast>
    </CToaster>
  </div>
</template>

<script setup>
import { useNotificationStore } from '@/stores/notificationStore'

const notificationStore = useNotificationStore()

const removeNotification = (id) => {
  notificationStore.remove(id)
}

const getColor = notificationType => {
  return notificationType === 'light'
    ? 'text-black'
    : 'text-light'
}

const isWhite = notificationType => {
  return notificationType !== 'light'
}
</script>
