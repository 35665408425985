import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView'
import AppLogin from '@/views/AppLogin'
import AppLogout from '@/views/AppLogout'
// import NotFound from '@/views/NotFound'
import { updateTitle } from './titlesGuard'
// import { useAuthStore } from '@/stores/authStore'
// import { useAuthStore } from '@/stores/authStore'
// import { checkCurrentLogin } from '@/composables/auth'
import { routeAllowed } from './authGuard'

const metaTitle = 'PRS Tools'
/* eslint-disable indent */

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      title: `Home - ${metaTitle}`
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: AppLogin,
    meta: {
      title: `Login - ${metaTitle}`
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: AppLogout,
    meta: {
      title: `Logout - ${metaTitle}`
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () =>
      import(/* webpackChunkName: 'dashboard' */ '../views/HomeView.vue'),
    meta: {
      title: `Dashboard - ${metaTitle}`
    }
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () =>
      import(/* webpackChunkName: 'about' */ '../views/AboutView.vue'),
    meta: {
      title: `About - ${metaTitle}`
    }
  },
  {
    path: '/me',
    name: 'Me',
    component: () =>
      import(/* webpackChunkName: 'me' */ '../views/users/UserSingle.vue'),
      meta: {
        title: `My Profile - ${metaTitle}`
      }
  },
  // {
    // path: '/users',
    // name: 'Users',
    // component: () =>
      // import(/* webpackChunkName: 'users' */ '../components/Users/UserList.vue'),
    // meta: {
      // title: `Users - ${metaTitle}`
    // }
  // },
  // {
    // path: '/users/:id',
    // name: 'User',
    // compnent: () =>
      // import(/* webpackChunkName: 'users/single' */ '../components/Users/UserSingle.vue'),
    // meta: {
      // title: `User Profile - ${metaTitle}`
    // }
  // },
  {
    path: '/customer-data-lake',
    name: 'CustomerLakeView',
    component: () => import(/* webpackChunkName: 'customer-data-lake' */ '../views/customerLake/CustomerLakeViewLoader.vue'),

    meta:
    {
      breadCrumb: 'Customer Data Lake',
      title: `Customer Lake - ${metaTitle}`,
      metaTags: [
        {
          name: 'description',
          content: 'PRS Customer Data Lake monitoring dashboard'
        }
      ]
    }
  },
  {
    path: '/customer-data-lake/jobs',
    name: 'CustomerLakeJobStatusView',
    component: () => import(/* webpackChunkName: 'customer-data-lake-jobs' */ '../views/customerLake/CustomerLakeJobStatusView.vue'),

    meta:
    {
      breadCrumb: 'Customer Data Lake - Jobs',
      title: `Customer Lake - ${metaTitle}`,
      metaTags: [
        {
          name: 'description',
          content: 'PRS Customer Data Lake job status monitoring'
        }
      ]
    }
  },
  {
    path: '/admin',
    component: () =>
      import(/* webpackChunkName: 'appAdmin' */'@/views/admin/AdminPanelViewLoader.vue'),
    roles: ['admin'],
    meta: {
      breadCrumb: 'Admin Panel',
      title: `Admin Panel - ${metaTitle}`,
      metaTags: [
        {
          name: 'description',
          content: 'PRStools administration panel'
        }
      ]
    }
  },
  {
    path: '/qbo',
    name: 'QboSearch',
    component: () =>
      import(/* webpackChunkName: 'qboCustomers' */'@/views/qbo/QboHomeLoader.vue'),
    props: true,
    meta:
    {
      breadCrumb: 'QBO Customers',
      title: `QBO Customers - ${metaTitle}`,
      metaTags: [
        {
          name: 'description',
          content: 'QBO Search'
        }
      ]
    }
  },
  {
    path: '/qbo/search',
    component: () =>
      import(/* webpackChunkName: 'qboSearch' */'@/components/qbo/QboSearch.vue'),
    meta: {
      breadCrumb: 'Qbo Search',
      title: `QBO Search - ${metaTitle}`
    }
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () =>
      import(/* webpackChunkName: 'customers' */ '../components/Customers/CustomerList.vue'),
    meta:
    {
      breadCrumb: 'Customers',
      title: `Customers - ${metaTitle}`,
      metaTags: [
        {
          name: 'description',
          content: 'A list of all customers'
        }
      ]
    }
  },
  {
    path: '/qbo/customers',
    component: () =>
      import(/* webpackChunkName: 'qboCustomers' */'../views/qbo/CustomersAll.vue'),
    roles: ['admin'],
    meta:
    {
      breadCrumb: 'Qbo Customer',
      title: `Qbo Customers - ${metaTitle}`,
      metaTags: [
        {
          name: 'description',
          content: 'All customers as stored in QBO'
        }
      ]
    },
    children: []
  },
  {
    path: '/customers/:id',
    component: () =>
      import(/* webpackChunkName: 'customers/single' */'../views/customers/CustomerViewLoader.vue'),
    props: true,
    meta:
    {
      breadCrumb: 'Customer',
      title: `Customer Profile - ${metaTitle}`,
      metaTags: [
        {
          name: 'description',
          content: 'A single customer\'s profile'
        }
      ]
    },
    children: [
      {
        // CustomerSubscriptions will be rendered inside Customer's <router-view>
        // when /customers/:id/subscriptions is matched
        path: '',
        name: 'CustomerProfile',
        component: () =>
          import(/* webpackChunkName: 'customers/single' */'../views/customers/CustomerProfile.vue'),
        meta:
        {
          breadCrumb: 'Customer',
          title: `Customer Profile - ${metaTitle}`
        }
      },
      {
        // ProductionLogs will be rendered inside Customer's <router-view>
        // when /customers/:id/invoices is matched
        path: 'invoices',
        name: 'CustomerInvoices',
        component: () =>
          import(/* webpackChunkName: 'views/InvoicesView' */'../views/billingOperations/InvoicesView.vue'),
        meta:
        {
          breadCrumb: 'Customers',
          title: `Customer Invoices - ${metaTitle}`
        }
      },
      {
        // CustomerSubscriptions will be rendered inside Customer's <router-view>
        // when /customers/:id/subscriptions is matched
        path: 'subscriptions',
        name: 'CustomerSubscriptions',
        component: () =>
          import(/* webpackChunkName: 'billingOperations/subscriptions' */'../views/billingOperations/SubscriptionsView'),
        meta:
        {
          breadCrumb: 'Customers',
          title: `Customer Subscriptions - ${metaTitle}`
        }
      }
    ]
  },
  {
    path: '/faxes',
    name: 'Faxes',
    component: () =>
      import(/* webpackChunkName: 'faxes' */ '../views/PrsFaxMonitor.vue'),
    meta:
    {
      breadCrumb: 'Faxes',
      title: `Faxes - ${metaTitle}`,
      metaTags: [
        {
          name: 'description',
          content: 'Monitor the PRS Fax system'
        }
      ]
    }
  },
  {
    path: '/tos',
    name: 'TermsOfService',
    component: () =>
      import(/* webpackChunkName: 'tos' */ '@/views/AppTos.vue')
  },
  {
    path: '/eula',
    name: 'Eula',
    component: () =>
      import(/* webpackChunkName: 'eula' */ '@/views/AppEula.vue')
  /*
  },
  {
    name: 'NotFound',
    component: NotFound,
    params: { pathMatch: 'not/found' },
    meta:
    {
      breadCrumb: 'NotFound',
      title: `404 Not Found - ${metaTitle}`
    }
  */
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// This callback runs before every route change, including on page load.
// router.beforeEach((to, from, next) => {
router.beforeEach((to, from) => {
  // console.debug('to.name: ', to.name)
  updateTitle(to, from)
})

router.beforeEach((to, from) => {
  return !routeAllowed(to)
    ? router.push('/')
    : true
})

export default router
