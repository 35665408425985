<template>
  <div class="home jumbotron mx-auto">
    <div class="bg-white text-dark jumbo-message">
      <h3>PRStools</h3>
      <h5>our Swiss Army Knife</h5>
    </div>
  </div>
</template>

<style scoped lang="scss">
.home.jumbotron {
  max-width: 1200px;
  padding-top: 6rem;
  background-blend-mode: darken;
  height: 35rem;
}
.jumbo-message {
  padding: 3rem;
  border-radius: 1rem;
  width: 60%;
  min-width: 30rem;
  font-size: 140%;
}
</style>
