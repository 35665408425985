<template>
  <CHeader class="bg-prstools" position="fixed">
    <CHeaderNav class="d-md-down-none mr-auto" fill="true"></CHeaderNav>
    <CHeaderNav>
      <CNavItem
        :href="helpdesk_base_url"
        target="_blank"
        rel="link"
        class="mx-2"
        v-c-tooltip="{ content: 'PRS Tickets' }"
      >
        <font-awesome-icon :icon="['fas', 'ticket']" size="2x"></font-awesome-icon>
      </CNavItem>
      <CNavItem
        :href="the_book_url"
        target="_blank"
        rel="link"
        class="mx-2"
        v-c-tooltip="{ content: 'The Book' }"
      >
        <font-awesome-icon :icon="['fas', 'book-open-reader']" size="2x"></font-awesome-icon>
      </CNavItem>
      <CNavItem
        href="https://teams.microsoft.com"
        target="_blank"
        rel="link"
        class="mx-2"
        v-c-tooltip="{ content: 'PRS Teams' }"
      >
        <font-awesome-icon :icon="['fas', 'fa-comment-dots']" size="2x"></font-awesome-icon>
      </CNavItem>
      <CNavItem
        href="https://outlook.office.com/mail"
        target="_blank"
        rel="link"
        class="email mx-0"
        v-c-tooltip="{ content: 'PRS Email' }"
      >
        <span class="fa-layers fa-fw fa-3x">
          <font-awesome-icon
            :icon="['fas', 'fa-envelope']"
            transform="shrink-5 up-2.5"
            class="m-0"
          ></font-awesome-icon>
          <!-- <span -->
            <!-- v-if="graphStore.unreadMessageCount" -->
            <!-- class="fa-layers-counter" -->
          <!-- > -->
            <!-- {{ Number(graphStore.unreadMessageCount).toLocaleString('us') }} -->
          <!-- </span> -->
        </span>
      </CNavItem>
      <DropdownAccount/>
    </CHeaderNav>
  </CHeader>
</template>

<script setup>
import DropdownAccount from './DropdownAccount'
import { useStore } from '@/stores/mainStore'
// import { useGraphStore } from '@/stores/graphStore'

const { helpdesk_base_url, the_book_url } = useStore() // eslint-disable-line camelcase
// const graphStore = useGraphStore()
// graphStore.getUnreadMessageCount()
</script>

<style lang="scss" scoped>
.bg-prstools {
  background: #93a299 !important;
}

.email > .nav-link { padding: 0 !important; background: blue;}
</style>
