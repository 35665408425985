export const updateTitle = (to, from) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  const previousNearestWithTitle = from.matched.slice().reverse().find(r => r.meta && r.meta.title)

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithTitle) {
    document.title = previousNearestWithTitle.meta.title
  }
}
