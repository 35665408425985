<template>
  <NotificationContainer/>
  <template v-if="authStore.isAuthenticated">
    <AppSidebar />
    <AppNavbar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light pt-4">
      <!-- <div class="sticky-top breadcrumbs"> -->
        <!-- <CBreadcrumb :items="breadcrumbs"/> -->
      <!-- </div> -->
      <CContainer fluid lg>
          <router-view />
      </CContainer>
    </div>
  </template>
  <template v-else>
    <router-view v-if="publicRoute(route.name)"/>
    <AppLogin v-else />
  </template>
    <AppFoot />
    <AppIdleModal />
</template>

<script setup>
import { watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useIdle } from '@vueuse/core'
import { useStore } from '@/stores/mainStore'
import { useAuthStore } from '@/stores/authStore'
import AppLogin from '@/views/AppLogin'
import AppSidebar from '@/components/AppSidebar'
import AppNavbar from '@/components/AppNavbar'
import AppFoot from '@/components/AppFoot'
import AppIdleModal from '@/components/AppIdleModal'
import NotificationContainer from '@/components/NotificationContainer'

const { app_login_expiry } = useStore() // eslint-disable-line camelcase
const authStore = useAuthStore()
const { idleModalVisible } = storeToRefs(authStore)

const route = useRoute()

const publicRoute = routeName => {
  const publicRoutePaths = ['AppLogin', 'Eula', 'TermsOfService']
  return publicRoutePaths.includes(routeName)
}

// const { idle } = useIdle(5000) // eslint-disable-line no-unused-vars
const { idle } = useIdle(app_login_expiry * 1000) // eslint-disable-line camelcase

watchEffect(() => {
  if (idle.value && authStore.isAuthenticated.value) {
    idleModalVisible.value = true
  }
})
</script>

<style lang="scss">
@import '@/assets/css/_colors.scss';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #666;
  .color-freshdesk {
    color: #25c16f;
  }
  .color-freshsales {
    color: #ffa800;
  }
  .fad-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .breadcrumbs {
    top: 57px !important;
    background: #fefefe;
  }
  .legal-terms {
    margin: 2rem 5rem;
    padding: 3rem;
    background: #ffffff;
    .subtitle {
      font-style: italic;
      color: #595959 !important;
      font-size: 14px !important;
    }
    .indent-1 {
      text-indent: 2rem;
    }
    .indent-1-block {
      margin-left: 2rem;
    }
    p {
      background: transparent !important;
      text-align: left;
      color: #292929 !important;
      font-size: 14px !important;
    }
    h2, h3, h4, h5 {
      color: #000000 !important;
    }
    h2, h3, h4, h5 {
      text-align: left;
    }
    h1 {
      font-size: 26px !important;
    }
    h2 {
      font-size: 21px !important;
    }
    h3 {
      font-size: 17px !important;
    }
    h4 {
      font-size: 15px !important;
      text-transform: uppercase;
      text-indent: 2rem;
    }
    li {
      text-align: left;
      color: #292929 !important;
    }
    ul li {
      list-style-type: square;
    }
  }
}
</style>
