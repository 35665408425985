import JwtDecode from 'jwt-decode'

export default class User {
  /* eslint-disable camelcase */
  static from (login_type, token) {
    try {
      // const obj = JwtDecode(token)
      return new User('msgraph', token)
    } catch (_) {
      return null
    }
  }

  // constructor ({ user_id, admin, email }) {
  // constructor ({ user_id, email }) {
  constructor (login_type, account, token) {
    if (login_type === 'msgraph') {
      Object.assign(this, {
        id: account.localAccountId,
        tenantId: account.tenantId,
        email: account.username,
        name: account.name,
        username: account.idTokenClaims.preferred_username,
        expiry: account.idTokenClaims.exp,
        idToken: token,
        roles: account.idTokenClaims.roles ? account.idTokenClaims.roles : []
      })
    } else {
      const token_vals = JwtDecode(account)
      Object.assign(this, {
        id: token_vals.user_id,
        email: token_vals.email
      })
    }
  }
  /* eslint-enable camelcase */

  /*
  get isAdmin () {
    return this.admin
  }
  */
}
