import { useAuthStore } from '@/stores/authStore'
import { isEmpty } from 'lodash'

/* eslint-disable no-unused-vars */
export const isAuthenticated = () => {
  // const router = useRouter()
  // const route = useRoute()
  const authStore = useAuthStore()

  return !isEmpty(authStore.currentUser)
}

export const routeAllowed = to => {
  const authStore = useAuthStore()
  return !to.meta.roles
    ? true
    : to.meta.roles.some(toRole => {
      return authStore.currentUser.roles.includes(toRole)
    })
}