// src/stores/index.js
import { defineStore } from 'pinia'
import ToolsConfig from '@/config/tools_config'

export const useStore = defineStore('main', () => {
  /* eslint-disable camelcase */
  const app_login_expiry = ToolsConfig.app_login_expiry
  const app_logout_timer = ToolsConfig.app_logout_timer
  const crm_base_url = ToolsConfig.crm_base_url
  const helpdesk_base_url = ToolsConfig.helpdesk_base_url
  const the_book_url = ToolsConfig.the_book_url
  const customer_lake = ToolsConfig.customer_lake

  return {
    app_login_expiry,
    app_logout_timer,
    crm_base_url,
    helpdesk_base_url,
    the_book_url,
    customer_lake
  }
  /* eslint-enable camelcase */
})
