// stores/sidebarStore.js
import { defineStore } from 'pinia'

export const useSidebarStore = defineStore('sidebar', {
  state: () => ({
    sidebarNarrow: false,
    sidebarUnfoldable: false,
    sidebarVisible: true,
    menubarVisible: true
  }),
  persist: {
    storage: sessionStorage
  },
  actions: {
    toggleSidebar (state) {
      this.sidebarNarrow = !this.sidebarNarrow
    },
    toggleUnfoldable (state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    toggleSidebarVisible () {
      this.sidebarVisible = !this.sidebarVisible
    },
    toggleMenuVisible () {
      this.menubarVisible = !this.menubarVisible
    }
  }
})
