export default [
  {
    component: 'CNavItem',
    name: 'Home',
    // to: '/dashboard',
    to: '/',
    icon: { type: 'fontawesome', class: 'fas fa-house' }
    // roles: ['admin']
  },
  {
    component: 'CNavItem',
    name: 'Customer Data Lake',
    to: '/customer-data-lake',
    icon: { type: 'fontawesome', class: 'fas fa-water' }
    // roles: ['admin', 'user']
  },
  {
    component: 'CNavItem',
    name: 'Customers',
    to: '/customers',
    icon: { type: 'fontawesome', class: 'fas fa-user-doctor' }
    // roles: ['admin', 'user']
  },
  {
    component: 'CNavItem',
    name: 'PRS Fax',
    to: '/faxes',
    icon: { type: 'fontawesome', class: 'fas fa-fax' }
    // roles: ['admin', 'user']
  },
  {
    component: 'CNavItem',
    name: 'Quickbooks',
    to: '/qbo',
    icon: { type: 'fontawesome', class: 'fas fa-book-open' },
    roles: ['admin']
  },
  {
    component: 'CNavItem',
    name: 'Admin Panel',
    to: '/admin',
    icon: 'cil-equalizer',
    roles: ['admin']
  }/*,
  {
    component: 'CNavItem',
    name: 'Tickets',
    href: 'https://support.prsdata.com',
    target: 'blank'
    // icon: { class: 'fas fa-ticket-alt' }
  },
  {
    component: 'CNavItem',
    name: 'Customer Reports',
    href: 'https://bid.slicedhealth.com',
    target: 'blank',
    icon: 'cil-chart'
  },
  {
    component: 'CNavItem',
    name: 'The Book',
    _attrs: { href: 'https://thebook.prsdata.net', target: 'blank' },
    icon: { class: 'fas fa-book-open' }
  },
  {
    component: 'CSidebarNavDropdown',
    _class: 'disabled',
    name: 'Tools',
    route: '/tools',
    icon: { class: 'fas fa-wrench' },
    items: [
      {
        component: 'CNavItem',
        name: 'Tool 1',
        to: '/'
      },
      {
        name: 'Tool 2',
        to: '/'
      }
    ]
  },
  {
    component: 'CSidebarNavDropdown',
    name: 'Playbook',
    icon: { class: 'fas fa-book-open' },
    items: [
      {
        component: 'CNavItem',
        name: 'Open Playbook',
        _attrs: { href: 'https://support.prsdata.com', target: 'blank' }
      },
      {
        name: 'Search The Book'
      }
    ]
  },
  {
    component: 'CSidebarNavDropdown',
    _target: 'blank',
    name: 'Customer Reports',
    to: 'https://bid.slicedhealth.com',
    icon: 'cil-chart',
    items: [
      {
        name: 'KPIs',
        to: '/'
      },
      {
        name: 'Costing',
        to: '/'
      }
    ]
  },
  {
    component: 'CSidebarNavDropdown',
    _class: 'disabled',
    name: 'PRS Reports',
    route: '/prs-reports',
    icon: 'cil-chart-line',
    items: [
      {
        name: 'Invoicing',
        to: '/'
      },
      {
        name: 'Costing',
        to: '/'
      },
      {
        name: 'Profitability',
        to: '/'
      }
    ]
  },
  {
    component: 'CSidebarNavDropdown',
    _class: 'disabled',
    name: 'Links',
    // to: '/links',
    to: '/',
    icon: 'cil-calculator',
    badge: {
      color: 'primary',
      text: 'NEW',
      shape: 'pill'
    }
  },
  {
    component: 'CNavItem',
    name: 'Download CoreUI',
    href: 'http://coreui.io/vue/',
    icon: { name: 'cil-cloud-download', class: 'text-white' },
    _class: 'bg-success text-white',
    target: '_blank'
  }
  */
]
