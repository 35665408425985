<template>
  <CFooter>
    <div class="legal">
      <ul>
        <li><router-link to="/tos">Terms of Service</router-link></li>
        <li><router-link to="/eula">EULA</router-link></li>
      </ul>
    </div>
    <hr/>
    <p class="text-muted">
      Built with ♥︎ by Owen by following <a target="_blank" href="https://github.com/paweljw">Paweł J. Wal</a>,
       <a target="_blank" href="https://coreui.io/vue/">CoreUI Vue</a>,
       and lot's of <a target="_blank" href="https://english.stackexchange.com/questions/19967/what-does-google-fu-mean#19973">GoogleFu</a>
    </p>
  </CFooter>
</template>

<script setup>
</script>

<style lang="scss">
.footer {
  .legal { padding-left: 18rem;}
  ul {
    li {
      display: inline-block;
      list-style-type: none;
      text-align: left;
      margin-right: 2rem;
    }
  }
}
</style>