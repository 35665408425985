<template>
  <div>
    Redirect to <a href="/">Home</a>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/authStore'

const router = useRouter()
const authStore = useAuthStore()

authStore.logout()
router.push('/')
</script>
