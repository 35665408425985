<template>
<div class="login-wrapper border border-light w-50 mx-auto">
  <div class="container signin-alt">
    <button @click="msLogin()" class="bg-white form-signin form-signin-alt form-signin-ms btn btn-outline-secondary btn-block">
      <svg class="icon" height="24px" width="24px" viewBox="44 129 24 24" xmlns="http://www.w3.org/2000/svg">
        <rect fill="#f65314" height="11.5121951" width="11.5121951" stroke="none" stroke-width="1" transform="translate(44.000000, 129.000000)" x="0" y="0"></rect>
        <rect fill="#7cbb00" height="11.5121951" width="11.5121951" stroke="none" stroke-width="1" transform="translate(44.000000, 129.000000)" x="12.4878049" y="0"></rect>
        <rect fill="#ffbb00" height="11.5121951" width="11.5121951" stroke="none" stroke-width="1" transform="translate(44.000000, 129.000000)" x="12.4878049" y="12.4878049"></rect>
        <rect fill="#00a1f1" height="11.5121951" width="11.5121951" stroke="none" stroke-width="1" transform="translate(44.000000, 129.000000)" x="0" y="12.4878049"></rect>
      </svg>
      Sign in with PRS Email Account
    </button>
  </div>
  <form class="form-signin d-none" @submit.prevent="login">
    <div class="alert alert-danger" v-if="error">{{ error }}</div>
    <label for="inputEmail" class="sr-only">Email address</label>
    <input v-model="email"
           type="email"
           id="inputEmail"
           class="form-control"
           placeholder="Email address"
           autocomplete="email"
           required
           autofocus>
    <label for="inputPassword" class="sr-only">Password</label>
    <input v-model="password"
           type="password"
           id="inputPassword"
           class="form-control"
           autocomplete="current-password"
           placeholder="Password"
           required>
    <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
  </form>
</div>
</template>

<script setup>
import { useAuthStore } from '@/stores/authStore'
/*
import { useMsal } from '@/composables/msal'

const authStore = useAuthStore()
const msal = useMsal(authStore.msalConfig)

msal.addEventCallback(message => {
  // console.debug('msal event message: ', message)
  const gotToken = [
    'msal:loginSuccess',
    'msal:acquireTokenSuccess'
  ].includes(message.eventType)

  if (gotToken) {
    const { payload } = message
    // Get idToken(JWT) from the payload.
    authStore.idToken = payload.idToken
  }
})
*/
const authStore = useAuthStore()

const msLogin = async () => {
  await authStore.msal.loginPopup({})
    .then(() => {
      const myAccounts = authStore.msal.getAllAccounts()
      // authStore.newLogin('msgraph', myAccounts[0], authStore.idToken)
      authStore.newLogin('msgraph', myAccounts[0])
      // location.reload()
    }).catch((error) => {
      // add a notice for failure
      console.error(error)
    })
  // checkCurrentLogin()
}

const email = ''
const password = ''
const error = false
</script>

<style lang="scss">
.login-wrapper {
  background: #f6f6f3;
  margin: 10rem auto 20rem auto;
  padding: 3rem;
}
</style>