import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowLeft,
  faBan,
  faBookOpen,
  faBookOpenReader,
  faBoxArchive,
  faCircleCheck,
  faCircleInfo,
  faCircleXmark,
  faCommentDots,
  faCopy,
  faEllipsisVertical,
  faEnvelope,
  faExternalLink,
  faExternalLinkAlt,
  faFax,
  faFileArrowDown,
  faFileCsv,
  faFloppyDisk,
  faHome,
  faPenToSquare,
  faPlus,
  faRotateRight,
  faTicket,
  faTrashCan,
  faTriangleExclamation,
  faUserDoctor,
  faWater
} from '@fortawesome/free-solid-svg-icons'
import {
  faIdBadge,
  faRectangleXmark
} from '@fortawesome/free-regular-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// app.component('font-awesome-layers', FontAwesomeLayers)
// app.component('font-awesome-layers-text', FontAwesomeLayersText)

library.add(
  faArrowLeft,
  faBan,
  faBookOpen,
  faBookOpenReader,
  faBoxArchive,
  faCircleCheck,
  faCircleInfo,
  faCircleXmark,
  faCommentDots,
  faCopy,
  faPenToSquare,
  faEllipsisVertical,
  faEnvelope,
  faExternalLink,
  faExternalLinkAlt,
  faFax,
  faFileArrowDown,
  faFileCsv,
  faFloppyDisk,
  faHome,
  faIdBadge,
  faPlus,
  faRotateRight,
  faTicket,
  faTrashCan,
  faTriangleExclamation,
  faUserDoctor,
  faWater,
  faRectangleXmark
)

export { library }